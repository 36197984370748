import React, { useCallback, useEffect, useRef } from "react"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import { navigate } from "gatsby"

import IdList from "./IdList"
import IdEdit from "./IdEdit"
import { Router } from "@gatsbyjs/reach-router"

import withAuth from "../../hooks/withAuth"
import { ToastContainer } from "react-toastify"
import DownloadCard from "./DownloadCard"
import CheckoutHistory from "./CheckoutHistory"
import CheckoutDetailContent from "./CheckoutDetailContent"
import ProfileSubscription from "./ProfileSubscription"
import ProfileSettings from "./ProfileSettings"
import BuyPhysicalCard from "./BuyPhysicalCard"

const Index = () => {
  return (
    <>
      <>
        <Header />
        <ToastContainer />

        <Router>
          <IdList path="/dashboard/" />
          <IdEdit path="/dashboard/edit-id" />
          <DownloadCard path="/dashboard/download" />
          <BuyPhysicalCard path="/dashboard/physical-card" />
          <CheckoutHistory path="/dashboard/checkout-history" />
          <CheckoutDetailContent path="/dashboard/checkout-detail" />
          <ProfileSubscription path="/dashboard/subscription" />
          <ProfileSettings path="/dashboard/settings" />
        </Router>
      </>
    </>
  )
}

export default withAuth(Index)
